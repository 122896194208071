import React, { useContext, memo } from 'react';

import FormModalContext from '../../context/FormModalContext';
import { ContactForm } from '.';
import { ContactFormModal } from './ContactFormModal';

const Form = ({
	baseUrl = '//app-sj02.marketo.com',
	munchkinId = '343-MGE-042',
	formId = '4865',
	contactForm,
	modal,
	noForm,
	contactUs = false,
	formImages,
	location,
	pardotUrl,
}) => {
	const {
		formModalOpen,
		handleModalClick,
		setFormModalOpen,
		email,
		specialModal,
		setSpecialModal,
	} = useContext(FormModalContext);

	const MemoizedForm = memo(ContactForm);
	const MemoizedModal = memo(ContactFormModal);

	//callback to prefill marketo forms
	// const callback = () => {
	// 	if (email) {
	// 		window.MktoForms2.onFormRender((form) => {
	// 			// console.log(form);
	// 			form.setValues({
	// 				Email: email,
	// 			});
	// 		});
	// 	}
	// };
	const handleModalClose = () => {
		setFormModalOpen(false);
		setSpecialModal(false);
	};
	return (
		<>
			{!formModalOpen ? (
				<MemoizedForm
					baseUrl={baseUrl}
					munchkinId={munchkinId}
					formId={formId}
					contactForm={contactForm}
					privacy
					modal={false}
					open={formModalOpen}
					// callback={callback}
					noForm={noForm}
					contactUs={contactUs}
					formImages={formImages}
					location={location}
					pardotUrl={pardotUrl}
				/>
			) : (
				<MemoizedModal
					open={formModalOpen}
					toggleOpen={setFormModalOpen}
					baseUrl='//app-sj02.marketo.com'
					munchkinId='343-MGE-042'
					formId={formId}
					contactForm={contactForm}
					// callback={callback}
					noForm={noForm}
					formImages={formImages}
					modal
					location={location}
					pardotUrl={
						specialModal
							? //swap this out for whatever special modal needs to be open from the promotion bar
							  'https://go.route-manager.com/l/1042281/2024-07-22/667d97'
							: pardotUrl
					}
					specialModal={specialModal}
					handleModalClose={handleModalClose}
				/>
			)}
		</>
	);
};

export default Form;
